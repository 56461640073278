var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "app-page"
  }, [_c('main-navigation', {
    attrs: {
      "main-navigation-items": _vm.mainNavigationItems
    }
  }), _c('div', {
    staticClass: "main-container"
  }, [_c('main-header', {
    attrs: {
      "show-project-dropdown": _vm.showProjectDropdown
    }
  }), _c('div', {
    staticClass: "container"
  }, [_c('router-view')], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }