var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "usermenu"
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  }), _c('div', {
    staticClass: "usermenu--options"
  }, [_c('v-avatar', {
    attrs: {
      "color": _vm.generateAvatarColor,
      "size": "25"
    }
  }, [_c('span', {
    staticClass: "white--text text-h6"
  }, [_vm._v(_vm._s(_vm.generatedAvatarInitials))])]), _c('span', {
    staticClass: "username"
  }, [_vm._v(" " + _vm._s(_vm.userData.user.username) + " ")]), _c('v-btn', {
    attrs: {
      "icon": ""
    }
  }, [_c('v-icon', {
    staticClass: "usermenu--popuptrigger",
    on: {
      "click": _vm.onClickDropdownMenu
    }
  }, [_vm._v(" expand_more ")])], 1)], 1), _vm.showDropdownMenu ? _c('div', {
    staticClass: "usermenu--dropdown px-6"
  }, [_c('v-row', {
    staticClass: "pt-4",
    attrs: {
      "justify": "space-between"
    }
  }, [_c('v-col', {
    staticClass: "usermenu--userinfo"
  }, [_c('ul', {
    staticClass: "usermenu--list"
  }, [_c('li', [_c('p', {
    staticClass: "usermenu--username font-weight-bold mb-2"
  }, [_vm._v(" " + _vm._s(_vm.generateUserName) + " ")])]), _c('li', [_c('p', {
    staticClass: "text-truncate"
  }, [_vm._v(" " + _vm._s(_vm.userData.user.username) + " ")])]), _c('li', [_c('router-link', {
    staticClass: "usermenu--link pb-0",
    attrs: {
      "to": "/logout"
    }
  }, [_c('v-btn', {
    staticClass: "usermenu--signout-btn"
  }, [_vm._v(" Sign out ")])], 1)], 1)])]), _c('v-col', {
    staticClass: "usermenu--avatar"
  }, [_c('v-avatar', {
    attrs: {
      "color": _vm.generateAvatarColor,
      "height": "64px",
      "width": "64px"
    }
  }, [_c('span', {
    staticClass: "white--text text-h6"
  }, [_vm._v(_vm._s(_vm.generatedAvatarInitials))])])], 1)], 1), _c('v-divider', {
    staticClass: "usermenu--divider"
  }), _c('v-row', {
    staticClass: "pt-4",
    attrs: {
      "justify": "space-around"
    }
  }, [_c('v-col', [_c('ul', {
    staticClass: "usermenu--list"
  }, [_c('li', {
    staticClass: "pb-3"
  }, [_c('a', {
    staticClass: "usermenu--link",
    attrs: {
      "href": "https://brandwatch-reviews-help.brandwatch.com/hc/en-us",
      "target": "_blank"
    }
  }, [_c('span', [_vm._v("Help")]), _c('v-icon', [_vm._v("open_in_new")])], 1)])])])], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }