var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "ma-0 projectdropdown"
  }, [_c('span', {
    staticClass: "projectdropdown--label"
  }, [_vm._v(_vm._s(_vm.$t('projectDropdown.title')))]), _c('r-select', {
    key: "projectdropdown--rselect--".concat(_vm.selectRenderCount),
    staticClass: "projectdropdown--select",
    attrs: {
      "items": _vm.items,
      "show-search": false,
      "allow-empty": false,
      "infinite": true
    },
    on: {
      "change": _vm.onChangeSelectedProject,
      "click:append-icon": _vm.onClickCog,
      "change:search": _vm.onSearchChange,
      "on:get-more": _vm.onGetMoreItems
    },
    scopedSlots: _vm._u([{
      key: "prepend-item",
      fn: function fn() {
        return [_c('v-col', {
          staticClass: "d-flex p-0 rselectitem"
        }, [_c('button', {
          staticClass: "projectdropdown--create-project px-2 py-2 rselectitem--row",
          on: {
            "click": _vm.onClickCreateNewProject
          }
        }, [_c('v-icon', {
          attrs: {
            "dense": ""
          }
        }, [_vm._v(" add ")]), _c('span', {
          staticClass: "ml-2"
        }, [_vm._v("Create a new project")])], 1)])];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }